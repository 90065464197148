import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserDto} from "../user/user-api";
import {SendNewEmailCodeCommand} from "../verification/verification-api";
import {TokenInfo} from "./auth-api";

const initialState: TokenInfo = {
  refresh_token: null,
  expires_in: undefined,
  scope: null,
  token_type: null,
  access_token: undefined,
  userInfo: {
    email: "",
  },
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    logout: () => {
      return initialState;
    },
    setStorageToken: (state, action: PayloadAction<TokenInfo>) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    getToken: (state) => {
      return state;
    },
    setStorageUserInfo: (state, action: PayloadAction<UserDto>) => {
      state.userInfo = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<SendNewEmailCodeCommand>) => {
      if (state.userInfo) state.userInfo.email = action.payload.email;
    },
  },
});

export default authSlice.reducer;

export const {logout, setStorageToken, setStorageUserInfo, setUserEmail} =
  authSlice.actions;
