import {Typography} from "@mui/material";
import React, {useMemo} from "react";
import {Trans} from "react-i18next";
import i18n from "i18next";

export default function useDeliveryTransitDaysAsterisk() {
  const asterisk = useMemo(() => {
    return (
      <Typography component={"span"} color={"primary.light"}>
        {" "}
        *
      </Typography>
    );
  }, []);

  const asteriskDescription = useMemo(() => {
    return (
      <Typography variant={"caption2"} color={"primary.light"}>
        {"* "}
        <Trans
          i18nKey={"delivery.deliveryMethods.transitDaysAsteriskDescription"}
        >
          Estimated from the moment of dispatch from the international hub
        </Trans>
      </Typography>
    );
  }, [i18n.language]);

  return {
    asterisk,
    asteriskDescription,
  };
}
