import {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {
  UploadFileSuccessResult,
  useFileUploader,
} from "../../../common/hooks/useFileUploader";
import {toastError} from "../../../common/utils/toastMessages";
import {toast} from "react-hot-toast";
import {t} from "i18next";

export interface UseCustomerDocumentUploaderProps {
  orderId: string;
  onUploadSuccess?: () => void;
}

interface CustomerDocumentsSettings {
  enabled: boolean;
  allowMultiple: boolean;
  maxFilesLimit: number;
  fileSizeLimitMB: number;
  allowedFileTypes: string[];
}

export function useCustomerDocumentUploader({
  orderId,
  onUploadSuccess,
}: UseCustomerDocumentUploaderProps) {
  const [showUploadCustomerDocument, setShowUploadCustomerDocument] =
    useState(false);

  const createCustomerDocumentAttachmentWorkflowId = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.workflows
        ?.createCustomerDocumentAttachmentWorkflowId,
  );

  const customerDocumentsSettings: CustomerDocumentsSettings = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.modules?.parcelTracking
        ?.customerDocumentsSettings,
  );

  const [runWorkflow] = useWorkflowExecutionMutation();

  const handleUploadCustomerDocument = useCallback(
    async (result: UploadFileSuccessResult) => {
      if (!orderId) {
        toastError("Order Id not found");
        return;
      }

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: createCustomerDocumentAttachmentWorkflowId,
        values: {
          variables: {
            fileName: result.file.name,
            fileUrl: result.url,
            orderId: orderId,
          },
        },
      };

      runWorkflow(executeWorkflowApiArgs)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          toastError(error);
        })
        .finally(() => {
          setShowUploadCustomerDocument(false);
        });
    },
    [createCustomerDocumentAttachmentWorkflowId],
  );

  const {uploadFileComponent: uploadCustomerDocumentComponent} =
    useFileUploader({
      maxNumberOfFiles: customerDocumentsSettings?.maxFilesLimit,
      fileSizeLimit: customerDocumentsSettings?.fileSizeLimitMB * 1024 * 1024,
      allowedFileTypes: customerDocumentsSettings?.allowedFileTypes,
      onUploadSuccess: async (result, uploader) => {
        await handleUploadCustomerDocument(result);
        if (
          uploader?.getFiles().every((file) => file.progress?.uploadComplete) &&
          onUploadSuccess
        ) {
          onUploadSuccess();
        }
      },
      onAllUploadedSuccess: async (uploader) => {
        toast.success(
          t(
            "parcelShipments.shipmentDetails.customerDocuments.uploadSuccess",
            "Files uploaded successfully",
          ),
        );
        uploader.clearUploadedFiles();
      },
    });

  return {
    uploadCustomerDocumentComponent,
    isUploaderVisible: showUploadCustomerDocument,
    setIsUploaderVisible: setShowUploadCustomerDocument,
  };
}
