import React, {useEffect, useRef} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {
  TextField as TF,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ErrorMessage} from "formik";

interface PinInputTemplateProps {
  pinLength: number;
  field: {name: string; value: string; onBlur: any};
  form: {setFieldValue: any; errors: any; touched: any};
}

const PinInputTemplate = ({
  pinLength,
  field: {name, value, onBlur},
  form: {setFieldValue, errors, touched},
  ...props
}: PinInputTemplateProps) => {
  const {t} = useTranslation();

  const fieldsRef = useRef<HTMLInputElement[]>([]);

  const moveFocus = (step: number) => {
    const index = step >= pinLength ? pinLength - 1 : step < 0 ? 0 : step;
    const nextTarget = fieldsRef.current[index];
    setTimeout(() => nextTarget?.focus(), 0);
  };

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const isValidatingError = !!errors?.[name] && touched?.[name];

  useEffect(() => {
    const fieldRef = fieldsRef.current;
    const fillFields = (value: string) => {
      for (let i = 0; i < pinLength; i++) {
        if (fieldRef[i]) fieldRef[i].value = value[i] || "";
      }
    };
    if (value) fillFields(value);
  }, [value, pinLength]);

  return (
    <>
      <Grid
        container
        direction="row"
        gap={1}
        flexWrap="nowrap"
        mb={1}
        mr={{xs: 1}}
        mx={0}
        maxWidth={1}
        justifyContent={"space-between"}
      >
        {Array(pinLength)
          .fill("")
          .map((_, i) => (
            <Grid key={`pin-grid-${name}-${i}`}>
              <TF
                data-testid={`pin-input-${name}-${i}`}
                onBlur={onBlur}
                {...props}
                error={isValidatingError}
                inputProps={{
                  min: 0,
                  style: {
                    textAlign: "center",
                    width: "100%",
                    minWidth: "1rem",
                    maxWidth: "3rem",
                  },
                }}
                inputRef={(ref) => (fieldsRef.current[i] = ref)}
                key={name + i}
                type="tel"
                onPaste={async (e) => {
                  e.preventDefault();
                  const data = e.clipboardData.getData("text/plain").trim();
                  if (data.length !== pinLength || isNaN(Number(data))) {
                    toast.error(t("toasts.incorrectValue"));
                  } else {
                    setFieldValue(name, data);
                  }
                }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val: any = e.target.value;
                  if (val === "") {
                    moveFocus(i - 1);
                  } else if (!isNaN(val) && val.length === 1) {
                    moveFocus(i + 1);
                  } else {
                    return;
                  }
                  const newPin = value.split("");
                  newPin[i] = val;
                  setFieldValue(name, newPin.join(""));
                }}
                value={value?.[i]}
                onFocus={(e) => e.target.select()}
              />
            </Grid>
          ))}
      </Grid>
      <ErrorMessage
        name={name}
        render={(msg: any) => (
          <Typography
            ml={1}
            mt={{md: 1}}
            color="error"
            sx={{
              fontWeight: "light",
              typography: {xs: "h2", md: "subtitle2"},
            }}
          >
            {msg}
          </Typography>
        )}
      />
    </>
  );
};

export default PinInputTemplate;
