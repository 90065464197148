import {RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useCallback, useEffect, useState} from "react";
import {toastError} from "../utils/toastMessages";
import {getPortalNameFromDomain} from "../OrganizationConfig/useOrganizationConfig";
import {
  CountriesQueryResult,
  setCountriesQuery,
} from "../../features/countries/countries-slice";

export enum CountryFilter {
  Default = "default",
  Delivery = "delivery",
  PhoneNumber = "phoneNumber",
}

interface UseGetCountriesProps {
  filter?: CountryFilter;
}

export const useGetCountries = ({
  filter = CountryFilter.Default,
}: UseGetCountriesProps) => {
  const dispatch = useDispatch();

  const getCountriesWorkflowId = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.workflows?.getCountriesWorkflowId,
  );

  const portalName =
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain();

  const countriesQuery = useSelector((state: RootState) =>
    state?.countriesState?.cachedQueries?.find(
      (x) => x.portalName === portalName && x.countryType === filter,
    ),
  );

  const [runWorkflow, {isSuccess, isError, error}] =
    useWorkflowExecutionMutation();
  const [isLoading, setIsLoading] = useState(false);

  const runGetCountriesWorkflow = useCallback(async () => {
    if (!getCountriesWorkflowId) {
      toastError("Missing workflow Id for getting countries");
      return null;
    }

    if (isLoading) {
      return null; // Prevent duplicate calls
    }

    setIsLoading(true);

    try {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: Number(process.env.REACT_APP_PORTAL_ORGANIZATION_ID),
        workflowId: getCountriesWorkflowId,
        values: {
          variables: {
            portalName,
            countryType: filter,
          },
        },
      };

      const getWorkflowResult = await runWorkflow(
        executeWorkflowApiArgs,
      ).unwrap();
      const fetchedCountries = getWorkflowResult?.outputs?.countries;

      if (!fetchedCountries) {
        toastError("Error loading countries");
        return null;
      }
      return fetchedCountries?.items;
    } catch (error) {
      toastError("Failed to fetch countries");
      console.error("Error fetching countries:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [getCountriesWorkflowId, runWorkflow, dispatch, isLoading]);

  useEffect(() => {
    if (!countriesQuery) {
      runGetCountriesWorkflow().then((result) => {
        const newCountriesQuery: CountriesQueryResult = {
          countries: result,
          portalName,
          countryType: filter,
        };
        dispatch(setCountriesQuery(newCountriesQuery));
      });
    }
  }, [dispatch, portalName, filter, countriesQuery]);

  return {
    data: countriesQuery?.countries,
    getCountries: runGetCountriesWorkflow,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
