import React, {useCallback, useState} from "react";
import {RootState, useAppSelector} from "../../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {
  ContactCategory,
  DeliveryAddressCommandValues,
} from "../components/interfaces";
import {toastError} from "../../../common/utils/toastMessages";
import {useModalForm} from "../../../common/hooks/useModalForm";
import Grid from "@mui/material/Unstable_Grid2";
import {Button, Typography} from "@mui/material";
import {customValueIsTrue} from "../../../../utils/helper.utils";
import {Trans} from "react-i18next";

export function useContactAddressUSPSValidation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);

  const {renderModal} = useModalForm({
    isOpen: modalIsOpen,
    content: modalContent,
  });

  const uspsAddressValidationWorkflowId = useAppSelector((state: RootState) => {
    return state?.organizationConfigState?.workflows
      ?.uspsAddressValidationWorkflowId;
  });

  const isEnabled = useAppSelector((state: RootState) => {
    return customValueIsTrue(
      state?.organizationConfigState?.modules?.address
        ?.enableShippingFromAddressUSPSValidation,
    );
  });

  const [runGetCountryProhibitedItemsWorkflow, {isLoading}] =
    useWorkflowExecutionMutation();

  const formValuesMapping = useCallback(
    (values: DeliveryAddressCommandValues): any => {
      const entirePostalCode = values.toAddressPostalCode ?? "";
      const [postalCode, postalCodeExtension] = entirePostalCode.includes("-")
        ? entirePostalCode.split("-")
        : [entirePostalCode, ""];

      return {
        streetName: values.toAddressStreetName,
        apartment: values.toAddressApartment,
        cityName: values.toAddressCity,
        regionCode: values.toAddressRegionCode,
        postalCode,
        postalCodeExtension,
        companyName:
          values?.toAddressContactCategory == ContactCategory.Business
            ? values.toAddressCompanyName
            : "",
      };
    },
    [],
  );

  const mapSuggestedAddressToFormValues = useCallback(
    (suggestedAddress: any) => {
      return {
        toAddressStreetName: suggestedAddress?.streetAddress,
        toAddressCity: suggestedAddress?.city,
        toAddressPostalCode: suggestedAddress?.postalCodeWithExtension,
        toAddressRegionCode: suggestedAddress?.state,
        toAddressApartment: suggestedAddress?.secondaryAddress
          ?.replace("#", "")
          ?.trim(),
      };
    },
    [],
  );

  const modalI18nKeyPrefix = "modalForm.suggestedAddressFoundModal.";

  const renderModalContent = useCallback(
    (modalValues: any, onSuccessCallback: any) => {
      if (
        modalValues.suggestedAddressFormatted &&
        modalValues.originalAddressFormatted
      ) {
        return (
          <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
          >
            <Typography variant={"h3"}>
              <Trans i18nKey={modalI18nKeyPrefix + "title"}>
                Suggested address found
              </Trans>
            </Typography>
            <Typography variant={"body4"}>
              <Trans i18nKey={modalI18nKeyPrefix + "subtitle"}>
                Please review yor address and make any necessary changes.
              </Trans>
            </Typography>
            <Typography variant={"body4"}>
              <Trans i18nKey={modalI18nKeyPrefix + "originalAddressTitle"}>
                Original address:
              </Trans>
            </Typography>
            <Grid>{modalValues.originalAddressFormatted}</Grid>
            <Typography variant={"body4"}>
              <Trans i18nKey={modalI18nKeyPrefix + "suggestedAddressTitle"}>
                Suggested address:
              </Trans>
            </Typography>
            <Grid>{modalValues.suggestedAddressFormatted}</Grid>
            <Grid display={"flex"} gap={2} mt={2}>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  if (onSuccessCallback) {
                    const suggestedAddress = modalValues.suggestedAddress;
                    const formValues =
                      mapSuggestedAddressToFormValues(suggestedAddress);
                    onSuccessCallback(formValues);
                  }
                  setModalIsOpen(false);
                }}
              >
                <Trans i18nKey={modalI18nKeyPrefix + "useSuggestedAddressBtn"}>
                  Use Suggested Address
                </Trans>
              </Button>
              <Button
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                  if (onSuccessCallback) {
                    onSuccessCallback();
                  }
                  setModalIsOpen(false);
                }}
              >
                <Trans i18nKey={modalI18nKeyPrefix + "keepOriginalAddressBtn"}>
                  Keep Original Address
                </Trans>
              </Button>
            </Grid>
          </Grid>
        );
      }
    },
    [],
  );

  const execute = useCallback(
    async (address: any, onSuccessCallback: any) => {
      if (!isLoading && isEnabled) {
        const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
          organizationId: process.env
            .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
          workflowId: uspsAddressValidationWorkflowId,
          values: {
            variables: {
              address,
            },
          },
        };

        const response: any = await runGetCountryProhibitedItemsWorkflow(
          executeWorkflowApiArgs,
        );

        const validationResults = response?.data?.outputs?.responseCustom;

        const validationErrors = validationResults?.errors;

        if (validationErrors?.length > 0) {
          validationErrors.map((error: any) => {
            toastError(error, null, {duration: 10000});
          });
          return false;
        }

        const validationModal = validationResults?.modal;

        if (validationModal) {
          setModalContent(
            renderModalContent(validationModal, onSuccessCallback),
          );
          setModalIsOpen(true);
        } else {
          onSuccessCallback();
        }

        return true;
      }
    },
    [uspsAddressValidationWorkflowId, isLoading],
  );

  const executeWithMapping = useCallback(
    (values: DeliveryAddressCommandValues, onSuccessCallback: any) => {
      return execute(formValuesMapping(values), onSuccessCallback);
    },
    [],
  );

  return {execute, executeWithMapping, renderModal, isEnabled};
}
