import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import {basePortalApi} from "../features/basePortalApi";
import authReducer from "../features/auth/auth-slice";
import userReducer from "../features/user/user-slice";
import verificationReducer from "../features/verification/verification-slice";
import parcelShipmentReducer from "../features/order/parcelShipment-slice";
import parcelReducer from "../features/parcel/parcel-slice";
import organizationConfigReducer from "../features/organizationConfig/organizationConfig-slice";
import prohibitedKeywordsReducer from "../features/prohibitedKeywords/prohibitedKeywords-slice";
import countriesReducer from "../features/countries/countries-slice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authState", "userState", "organizationConfigState"],
  blacklist: [basePortalApi.reducerPath],
};

const rootReducer = combineReducers({
  [basePortalApi.reducerPath]: basePortalApi.reducer,
  authState: authReducer,
  userState: userReducer,
  verificationState: verificationReducer,
  parcelShipmentState: parcelShipmentReducer,
  organizationConfigState: organizationConfigReducer,
  parcelState: parcelReducer,
  prohibitedKeywordsState: prohibitedKeywordsReducer,
  countriesState: countriesReducer,
});

const persistdReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistdReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }).concat([basePortalApi.middleware, thunk]),
});

export const persistor = persistStore(store);
