import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import CustomsDeclarationDetailsComponent from "./customsDeclarationDetailsComponent";
import {LoadingButton} from "@mui/lab";
import {CustomsDeclarationFragmentProps} from "./interfaces";
import {
  CommodityCustomValues,
  CommodityDto,
  CreateCommodityPortalCommand,
  DimensionsUnits,
  OrderDto,
  PurposeOfCommodityValues,
  UpdateCommodityPortalCommand,
  UpdateOrderPortalCommand,
  VolumeUnit,
  WeightUnits,
} from "../../../features/order/order-api";
import {useParcelShipmentSubmit} from "../../hooks/UseParcelShipmentSubmit";
import {usePurchaseCommodityUpdate} from "../hooks/usePurchaseCommodityUpdate";
import {Formik, FormikHelpers, FormikProps} from "formik";
import {Helmet} from "react-helmet";
import {useAppSelector} from "../../../store";
import {getPortalNameFromDomain} from "../../../common/OrganizationConfig/useOrganizationConfig";
import {defaultCommodityType} from "./customsDeclarationItemsDetails";
import {toastError} from "../../../common/utils/toastMessages";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";
import {useDeclarationValidationSchema} from "../pages/validation";
import {useDispatch} from "react-redux";
import {setCustomerNotes} from "../../../features/order/parcelShipment-slice";

export type CustomsDeclarationFormValues = UpdateOrderPortalCommand & {
  isGovernmentChecked: boolean;
  customerNotes?: string;
};

export const initialCustomsDeclarationValues: CustomsDeclarationFormValues[] = [
  {
    trackingNumber: "",
    commodities: [],
    container: {
      weight: null,
      pieces: 1,
      customValues: {
        dangerousGoods: false,
        lithiumBatteriesGoods: false,
        purposeOfCommodity: PurposeOfCommodityValues.Gift,
      },
    },
    orderEntities: [],
    charges: [],
    isGovernmentChecked: false,
    customerNotes: "",
  },
];

const getCommodityCustomValues = (
  commodities?: CommodityDto[] | null,
): CommodityCustomValues => {
  const commodityWithCustomValues = commodities?.find(
    (commodity) =>
      commodity?.customValues &&
      typeof commodity.customValues === "object" &&
      Object.keys(commodity.customValues).length > 0,
  );
  if (commodityWithCustomValues?.customValues) {
    return {
      ...commodityWithCustomValues.customValues,
    } as CommodityCustomValues;
  } else {
    return {
      purposeOfCommodity: PurposeOfCommodityValues.Gift,
      dangerousGoods: false,
      lithiumBatteriesGoods: false,
    } as CommodityCustomValues;
  }
};

export default function CustomsDeclarationFragment({
  handleNext,
  pageTitle,
  config,
}: CustomsDeclarationFragmentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const {orderIds, isConsolidated, currentOrder} = useDeliveryHelper();
  const [initialValue, setInitialValue] = useState<
    CustomsDeclarationFormValues[]
  >(initialCustomsDeclarationValues);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {getPurchase} = useParcelShipmentSubmit();

  const {getDeclarationSchema} = useDeclarationValidationSchema();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && orderIds)
      if (isConsolidated) {
        setIsLoading(true);

        const getPurchasesPromises = orderIds?.map((purchaseId) =>
          getPurchase(purchaseId),
        );

        Promise.all(getPurchasesPromises)
          .then((responses) => {
            if (responses.some((x: any) => x.error)) {
              toastError(t("toasts.serverError"));
              return;
            }
            const purchasesInitialValues = responses.map((response: any) => {
              const order: OrderDto = response.data;
              const addedCommodity: CreateCommodityPortalCommand[] = [];

              if (
                response.data?.commodities.length &&
                response.data?.commodities[0].containerCommodities
              ) {
                response.data.commodities[0].containerCommodities.map(
                  (item: CommodityDto) => {
                    addedCommodity.push({
                      description: item?.description,
                      note: item?.note,
                      quantity: item?.quantity,
                      unitaryValue: item?.unitaryValue,
                      uuid: item?.commodityId,
                      customValues: item?.customValues ?? null,
                      weight: item?.weight,
                      pieces: item?.pieces,
                      unit: item?.unit,
                      commodityType:
                        item?.commodityType ?? defaultCommodityType,
                      containerCommodities: [],
                      weightUnit: WeightUnits.Kg,
                      dimensionsUnit: DimensionsUnits.Cm,
                    });
                  },
                );
              }
              const commodityWithCustomValues: CommodityCustomValues =
                getCommodityCustomValues(order?.commodities);

              const container: UpdateCommodityPortalCommand = {
                weight: order.commodities?.[0].weight,
                weightUnit: config?.defaultWeightUnits ?? WeightUnits.Lb,
                pieces: 1,
                dimensionsUnit:
                  config?.defaultLengthUnits ?? DimensionsUnits.In,
                volumeUnit: (config?.defaultVolumeUnits as VolumeUnit) ?? "Vkg",
                customValues: commodityWithCustomValues,
              };

              return {
                customValues: order?.customValues,
                trackingNumber: order?.trackingNumber ?? "",
                orderEntities: order?.orderEntities ?? [],
                commodities: addedCommodity,
                charges: order?.charges ?? [],
                container,
                customerNotes: currentOrder?.customValues?.customer_notes,
              };
            });

            setInitialValue((prev) => {
              const values = [...prev];
              purchasesInitialValues.map((purchaseInitialValue: any, index) => {
                values[index] = {
                  ...values[index],
                  ...purchaseInitialValue,
                };
              });

              return values;
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);

        getPurchase(orderIds?.[0])
          .then((response: any) => {
            const order: OrderDto = response.data;
            const addedCommodity: CreateCommodityPortalCommand[] = [];

            if (
              response.data?.commodities.length &&
              response.data?.commodities[0].containerCommodities
            ) {
              response.data.commodities[0].containerCommodities.map(
                (item: CommodityDto) => {
                  addedCommodity.push({
                    description: item?.description,
                    note: item?.note,
                    quantity: item?.quantity,
                    unitaryValue: item?.unitaryValue,
                    uuid: item?.commodityId,
                    customValues: item?.customValues ?? null,
                    weight: item?.weight,
                    pieces: item?.pieces,
                    unit: item?.unit,
                    commodityType: item?.commodityType,
                    containerCommodities: [],
                    weightUnit: WeightUnits.Kg,
                    dimensionsUnit: DimensionsUnits.Cm,
                  });
                },
              );
            }
            const commodityWithCustomValues: CommodityCustomValues =
              getCommodityCustomValues(order?.commodities);

            const container: UpdateCommodityPortalCommand = {
              weight: order.commodities?.[0].weight,
              weightUnit: config?.defaultWeightUnits ?? WeightUnits.Lb,
              pieces: 1,
              dimensionsUnit: config?.defaultLengthUnits ?? DimensionsUnits.In,
              volumeUnit: (config?.defaultVolumeUnits as VolumeUnit) ?? "Vkg",
              customValues: commodityWithCustomValues,
            };

            setInitialValue((prev) => {
              const values = [...prev];
              values[0] = {
                ...values[0],
                customValues: order?.customValues,
                trackingNumber: order?.trackingNumber ?? "",
                orderEntities: order?.orderEntities ?? [],
                commodities: addedCommodity,
                charges: order?.charges ?? [],
                container,
                customerNotes: currentOrder?.customValues?.customer_notes,
              };
              return values;
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
  }, []);

  const {onUpdate} = usePurchaseCommodityUpdate();

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createUpdatePurchaseWorkflowId,
  );

  const handleOnSubmit = async (
    data: CustomsDeclarationFormValues[],
    isMainSubmit = false,
    formikHelpers?: FormikHelpers<CustomsDeclarationFormValues[]>,
  ) => {
    data[0].workflowId = workflowId;

    const values = [...data];

    const customValues = {
      ...data[0].container?.customValues,
    };

    values.forEach((item) => {
      item.container = {
        ...data[0].container,
        customValues,
      };

      if (
        item.commodities &&
        item.commodities.length === 1 &&
        item.commodities[0].description?.trim() === ""
      ) {
        item.commodities = [];
      }
    });

    const customerNotes = data?.[0]?.customerNotes?.trim();
    dispatch(setCustomerNotes({orderIds, customerNotes}));

    let updatePurchasesPromises: Promise<any>[];

    if (orderIds && orderIds.length > 1 && isConsolidated) {
      updatePurchasesPromises = values.map((data, purchaseIndex) => {
        return onUpdate(
          orderIds[purchaseIndex],
          data,
          {
            dangerousGoods: values[0].container?.customValues?.dangerousGoods,
            lithiumBatteriesGoods:
              values[0].container?.customValues?.lithiumBatteriesGoods,
            purposeOfCommodity:
              values[0].container?.customValues?.purposeOfCommodity,
          },
          data.orderEntities,
        );
      });
    } else {
      updatePurchasesPromises = [
        onUpdate(
          Number(orderIds[0]),
          values[0],
          {
            dangerousGoods: values[0].container?.customValues?.dangerousGoods,
            lithiumBatteriesGoods:
              values[0].container?.customValues?.lithiumBatteriesGoods,
            purposeOfCommodity:
              values[0].container?.customValues?.purposeOfCommodity,
          },
          values?.[0].orderEntities,
        ),
      ];
    }
    Promise.all(updatePurchasesPromises)
      .then(() => {
        if (isMainSubmit) handleNext();
      })
      .catch(() => {
        toastError(t("toasts.purchaseUpdateError"));
      })
      .finally(() => {
        if (formikHelpers) formikHelpers.setSubmitting(false);
        formikHelpers?.validateForm();
      });
  };

  return isLoading ? (
    <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Formik
        initialValues={initialValue}
        onSubmit={(values: CustomsDeclarationFormValues[], formikHelpers) => {
          handleOnSubmit(values, true, formikHelpers);
        }}
        validationSchema={getDeclarationSchema(
          t,
          process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain(),
        )}
      >
        {(formikProps: FormikProps<CustomsDeclarationFormValues[]>) => (
          <Grid container columns={{xs: 6, md: 12}}>
            <Grid md={12}>
              <Grid md={6} sx={{display: isDesktop ? "block" : "none"}}>
                <Typography variant={"body2"} fontSize={"1rem"} mb={2}>
                  <Trans i18nKey="delivery.declaration.notificationDeclaration">
                    Notice! Customs authorities require complete and accurate
                    item descriptions. Provide an accurate value. Customs
                    officials inspect the shipments entering and leaving the
                    country for compliance.
                  </Trans>
                </Typography>
                <Box
                  mb={4}
                  sx={{
                    border: "1px solid #2F81EF",
                    background: "#F5F9FF",
                    borderRadius: "100px",
                    padding: "1rem 2rem 1rem 2rem",
                  }}
                >
                  <Typography
                    textAlign={"start"}
                    variant="body2"
                    fontSize={"1rem"}
                  >
                    <Trans i18nKey="delivery.declaration.exampleDeclaration">
                      Example. Descriptions Parts, Gifts, Textiles or Samples
                      are too vague and can`t be accepted. Description Woman`s
                      T-Shirts made of cotton is correct.
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid>
                <CustomsDeclarationDetailsComponent
                  saveToStorage={(values: CustomsDeclarationFormValues[]) => {
                    handleOnSubmit(values);
                  }}
                  isConsolidated={isConsolidated}
                  purchases={initialValue}
                  config={config}
                />
              </Grid>
              <Grid mt={isDesktop ? 0 : 4}>
                <Grid mb={4}>
                  <Grid md={2}>
                    <LoadingButton
                      data-testid="btn-continue-customs-declaration"
                      sx={{mt: 3, mb: 1}}
                      fullWidth
                      type="submit"
                      variant="contained"
                      form="addCustomValuesCommodityForm"
                      color="secondary"
                      loadingPosition="end"
                      endIcon={<></>}
                      disabled={
                        formikProps.isSubmitting ||
                        formikProps.values!.some(
                          (item) =>
                            item &&
                            item.commodities &&
                            item.commodities.length! < 1,
                        )
                      }
                      loading={formikProps.isSubmitting}
                    >
                      <Trans i18nKey="btnContinue">Continue</Trans>
                    </LoadingButton>
                  </Grid>
                  <Grid
                    display={
                      formikProps.values!.some(
                        (item) =>
                          item &&
                          item.commodities &&
                          item.commodities.length! < 1,
                      )
                        ? "flex"
                        : "none"
                    }
                  >
                    <Typography
                      variant="body1"
                      color={"red"}
                      mt={1}
                      fontSize={"14px"}
                    >
                      <Trans i18nKey={"validation.declaration.packageNoItems"}>
                        Package must contain at least one item!
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}
