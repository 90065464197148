import React from "react";
import OneTimeCodeLoginForm from "../components";
import SplitPage from "../../common/SplitPage/components/splitPage";

export const OneTimeCodeLogin = () => {
  return (
    <SplitPage
      contentSide="leftCentered"
      main={<OneTimeCodeLoginForm />}
      pageTitle="One-Time Code Login"
    />
  );
};

export default OneTimeCodeLogin;
