import {Button, Link as MuiLink, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import {Field, Form, Formik} from "formik";
import {Trans, useTranslation} from "react-i18next";
import {GetTokenCommand} from "../../features/auth/auth-api";
import i18next from "i18next";
import {useLoginFormSubmit} from "../../LogIn/hooks/useLoginFormSubmit";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import PinInputTemplate from "../../Verifications/components/pinInputComponent";
import Link from "../../common/components/link";
import {ArrowBackIos} from "@mui/icons-material";
import useSendOneTimeLoginCode, {
  SendOneTimeLoginCodeRequest,
} from "../hooks/useSendOneTimeLoginCode";
import {getOneTimeLogInValidationSchema} from "../pages/validation";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";

const defaultCodeLogInInitialValues: GetTokenCommand & {tempEmail: string} = {
  tempEmail: "",
  email: "",
  code: "",
  grantType: "otac",
  organizationId: 0,
};

export default function OneTimeCodeLoginForm() {
  const {t} = useTranslation();

  const userInfo = useSelector((state: RootState) => state.authState.userInfo);

  const [initialValues] = React.useState({
    ...defaultCodeLogInInitialValues,
    email: userInfo?.email ?? "",
    tempEmail: userInfo?.email ?? "",
    isEmailEdit: !userInfo?.email,
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
  });

  const {sendCode, isLoading} = useSendOneTimeLoginCode();

  const handleSendCodeSubmit = async (values: any) => {
    const request: SendOneTimeLoginCodeRequest = {
      requestedEmail: values.email,
    };

    await sendCode(request);
  };

  const {onSubmitHandler} = useLoginFormSubmit();

  const handleSubmitCode = (values: GetTokenCommand) => {
    onSubmitHandler(values);
  };

  return (
    <Grid
      container
      columns={{xs: 6, md: 6}}
      rowSpacing={{xs: 3, md: 4}}
      height={"100%"}
      justifyContent="center"
    >
      <Grid p={0} xs={6} md={4} pt={"40px"} position={"relative"}>
        <Grid display={{xs: "none", md: "flex"}}>
          <Link to="../login">
            <ArrowBackIos sx={{color: "black"}} />
          </Link>
        </Grid>
        <Typography
          sx={{
            fontSize: "2rem",
            fontFamily: "Inter SemiBold",
          }}
        >
          <Trans i18nKey="oneTimeCode">One-Time Code</Trans>
        </Typography>

        <Formik
          onSubmit={async (values: GetTokenCommand) => {
            handleSubmitCode(values);
          }}
          initialValues={initialValues}
          validationSchema={getOneTimeLogInValidationSchema(t)}
        >
          {(formik) => {
            return (
              <Form>
                <Grid py={0}>
                  <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    py={0}
                  >
                    {formik.values.isEmailEdit ? (
                      <Grid display={"flex"} flexDirection={"column"} gap={2}>
                        <Field
                          fullWidth
                          component={TextField}
                          name="tempEmail"
                          type="email"
                          label={t("email")}
                          placeholder="example@gmail.com"
                          data-testid="input-email"
                          autoComplete="email"
                          InputLabelProps={{shrink: true}}
                        />
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          data-testid="btn-fill-email"
                          disabled={!!formik.errors.tempEmail}
                          onClick={() => {
                            if (
                              !formik.values.tempEmail ||
                              formik.errors.tempEmail
                            ) {
                              return;
                            }
                            if (formik.values.tempEmail !== formik.values.email)
                              handleSendCodeSubmit({
                                email: formik.values.tempEmail,
                              });
                            formik.setFieldValue(
                              "email",
                              formik.values.tempEmail,
                            );
                            formik.setFieldValue("isEmailEdit", false);
                          }}
                        >
                          <Trans i18nKey="">Next</Trans>
                        </Button>
                      </Grid>
                    ) : (
                      <Grid display={"flex"} flexDirection={"column"} gap={2}>
                        <Typography>
                          <Trans i18nKey={"enterOneTimeCodeSentToEmail"}>
                            Enter the code that has been sent to
                          </Trans>{" "}
                          <b>{formik.values.email}</b>{" "}
                          <Typography
                            data-testid={`btn-edit-email-address`}
                            sx={{cursor: "pointer"}}
                            onClick={() => {
                              formik.setFieldValue("isEmailEdit", true);
                            }}
                            color="primary"
                            variant="body1"
                            component="span"
                          >
                            <Trans i18nKey={"btnEdit"}>Edit</Trans>
                          </Typography>
                        </Typography>
                        <Grid py={0}>
                          <Field
                            component={PinInputTemplate}
                            name="code"
                            pinLength={6}
                          />
                        </Grid>
                        <Grid py={0}>
                          <Typography
                            variant="body4"
                            sx={{
                              color: "#6C757D",
                              display: "block",
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{color: "inherit"}}
                            >
                              <Trans i18nKey="isPinCodeWasSend">
                                Did not receive a code?
                              </Trans>{" "}
                            </Typography>
                            <Typography
                              component="span"
                              sx={{color: "inherit"}}
                            >
                              <Button
                                variant="link"
                                disabled={isLoading}
                                data-testid={`resend-email-code-btn`}
                                onClick={() =>
                                  handleSendCodeSubmit({
                                    email: formik.values.email,
                                  })
                                }
                              >
                                <Trans i18nKey="resendPinCodeToEmailButton">
                                  Click here
                                </Trans>
                              </Button>
                              &nbsp;
                              <Trans i18nKey="resendPinCodeLabel">
                                to resend it
                              </Trans>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          display={"flex"}
                          flexDirection={"column"}
                          py={0}
                        >
                          <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="secondary"
                            data-testid="btn-submit"
                            loading={isLoading}
                          >
                            <Trans i18nKey="signInButton">Log In</Trans>
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <Grid py={0}>
          <Grid textAlign={"center"} py={0}>
            <Typography variant="caption1">
              <Trans i18nKey="helpQuestion">
                Need help?&nbsp;
                <MuiLink
                  href={
                    "https://trtshopping.com/" +
                    (i18next.language !== "ua"
                      ? `ua/${i18next.language}/`
                      : `${i18next.language}/`) +
                    "help/"
                  }
                  underline="none"
                  target="_blank"
                  rel="noopener"
                >
                  Contact Support
                </MuiLink>
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
