import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CountryDto} from "./countries-api";

export interface CountriesQueryResult {
  portalName: string;
  countryType: string;
  countries: CountryDto[];
}

export interface CountriesState {
  cachedQueries: CountriesQueryResult[];
}

const initialState: CountriesState = {
  cachedQueries: [],
};

export const countriesSlice = createSlice({
  initialState,
  name: "countriesSlice",
  reducers: {
    setCountriesQuery: (
      state: CountriesState,
      action: PayloadAction<CountriesQueryResult>,
    ) => {
      const queryResult = action.payload as CountriesQueryResult;

      const cachedResult = state.cachedQueries?.find(
        (x) =>
          x.portalName === queryResult.portalName &&
          x.countryType === queryResult.countryType,
      );

      if (cachedResult) {
        cachedResult.countries = queryResult.countries;
      } else {
        state.cachedQueries?.push(queryResult);
      }
    },
  },
});

export default countriesSlice.reducer;

export const {setCountriesQuery} = countriesSlice.actions;
