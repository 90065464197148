import React, {useCallback, useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import {Form, Formik, FormikProps} from "formik";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import {LoadingButton} from "@mui/lab";
import {useTheme} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {setOrderCustomValues} from "../../features/parcel/parcel-slice";
import {ContactAddressDto} from "../../features/contactAddress/contactAddress-api";
import {Trans} from "react-i18next";
import GooglePlacesAutocomplete from "./googlePlacesAutocomplete";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import {getCustomValue} from "../../../utils/helper.utils";
import Paper from "@mui/material/Paper";
import i18next, {t} from "i18next";
import parse from "html-react-parser";
import {useNavigate} from "react-router-dom";

type ContactAddress = ContactAddressDto & {
  distance?: number;
};

type DropOffLocationFormValues = {
  selected_agent_contact_id: number | null;
  selected_agent_address_id: number | null;
  placeSearch: any;
  placeSearchValue: any;
};

type DropOffLocationsMapProps = {
  handleNext: any;
};

export default function DropOffLocationsMap({
  handleNext,
}: DropOffLocationsMapProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const i18nextKeyPrefix = "delivery.dropOffLocations.";

  const deliveryConfig = useSelector(
    (state: RootState) => state?.organizationConfigState?.modules?.delivery,
  );
  const noLocationsFoundMessage = useMemo(() => {
    const message = deliveryConfig?.noLocationsFound?.[i18next.language];
    if (message) return parse(message);
    return null;
  }, [deliveryConfig, i18next.language, parse]);
  const noLocationsFoundLink = useMemo(() => {
    return deliveryConfig?.noLocationsFound?.link ?? null;
  }, [deliveryConfig]);

  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.parcelState?.order);
  const contactAddress = useSelector(
    (state: RootState) => state.parcelState?.order?.contactAddressValues,
  );

  const googlePlacesLibrary = useMapsLibrary("places");
  const autocompleteService = useMemo(
    () => googlePlacesLibrary && new googlePlacesLibrary.AutocompleteService(),
    [googlePlacesLibrary],
  ) as google.maps.places.AutocompleteService | null;
  const placesService = useMemo(
    () =>
      googlePlacesLibrary &&
      new googlePlacesLibrary.PlacesService(document.createElement("div")),
    [googlePlacesLibrary],
  ) as google.maps.places.PlacesService | null;

  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);

  const [autocompleteInitialValue, setAutocompleteInitialValue] =
    useState<google.maps.places.AutocompletePrediction | null>();
  const [placeInitialValue, setPlaceInitialValue] =
    useState<google.maps.places.PlaceResult | null>();

  const contactAddressLocation = useMemo(() => {
    if (contactAddress?.latitude && contactAddress?.longitude) {
      return {
        lat: contactAddress.latitude,
        lng: contactAddress.longitude,
      };
    }
    if (placeInitialValue?.geometry?.location) {
      return {
        lat: placeInitialValue.geometry.location.lat(),
        lng: placeInitialValue.geometry.location.lng(),
      };
    }
    return null;
  }, [contactAddress, placeInitialValue?.geometry?.location]);

  const initialValues: DropOffLocationFormValues = useMemo(() => {
    const selected_agent_contact_id = getCustomValue(
      order?.customValues,
      "selected_agent_contact_id",
    );
    const selected_agent_address_id = getCustomValue(
      order?.customValues,
      "selected_agent_address_id",
    );

    setTimeout(() => {
      setInitialValuesLoaded(true);
    }, 2000);

    return {
      selected_agent_contact_id: selected_agent_contact_id
        ? Number(selected_agent_contact_id)
        : null,
      selected_agent_address_id: selected_agent_address_id
        ? Number(selected_agent_address_id)
        : null,
      placeSearch: autocompleteInitialValue ?? null,
      placeSearchValue: placeInitialValue ?? null,
    };
  }, [
    order,
    contactAddressLocation,
    autocompleteInitialValue,
    placeInitialValue,
    setInitialValuesLoaded,
  ]);

  useEffect(() => {
    if (contactAddress) {
      const formattedAddress =
        [contactAddress?.streetName, contactAddress?.city]?.join(", ") ?? "";

      const initialPlaceSearchString =
        contactAddress?.postalCode ?? formattedAddress;

      autocompleteService?.getPlacePredictions(
        {
          input: initialPlaceSearchString,
          location: contactAddressLocation
            ? new google.maps.LatLng(contactAddressLocation)
            : undefined,
          radius: contactAddressLocation ? 100 : undefined,
          language: "en",
          types: ["geocode"],
          region: "us",
          componentRestrictions: {
            country: contactAddress?.countryCode?.toLowerCase() ?? "us",
          },
        },
        (result, status) => {
          if (status == "OK" && result) {
            const autocompleteValue = result[0];
            if (!autocompleteValue) return;
            setAutocompleteInitialValue(autocompleteValue);
            if (!autocompleteValue?.place_id) return;
            placesService?.getDetails(
              {
                placeId: autocompleteValue?.place_id,
                language: "en",
                fields: [
                  "geometry.location",
                  "formatted_address",
                  "address_components",
                ],
              },
              (result, status) => {
                if (status == "OK" && result) {
                  const placeValue = result;
                  if (!placeValue) return;
                  setPlaceInitialValue(placeValue);
                  setInitialValuesLoaded(true);
                }
              },
            );
          }
        },
      );
    }
  }, []);

  const mapsLib = useMapsLibrary("maps");
  const isGoogleMapsLibLoaded = useMemo(() => !!mapsLib, [mapsLib]);
  const [zoom, setZoom] = useState<number>(2);
  const map = useMap();

  const [runWorkflow, {isLoading: isDropOffLoading}] =
    useWorkflowExecutionMutation();
  const getDropOffLocationsWorkflowId = useSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.getDropOffLocationsWorkflowId,
  );
  const [dropOffLocations, setDropOffLocations] = useState<any[]>([]);

  const loadDropOffLocations = useCallback(
    async (contactAddressLocation: any) => {
      const dropOffSettings = deliveryConfig?.dropOffSettings;
      const data = {
        currentLocation: contactAddressLocation,
        limit: dropOffSettings?.limit,
        radius: dropOffSettings?.radius,
      };
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: getDropOffLocationsWorkflowId,
        values: {variables: {...data}},
      };
      const workflowResult: any = await runWorkflow(executeWorkflowApiArgs);
      if (workflowResult.data) {
        setDropOffLocations(
          workflowResult.data.outputs?.contactAddresses ?? [],
        );
      }
    },
    [isGoogleMapsLibLoaded, contactAddressLocation, runWorkflow],
  );

  useEffect(() => {
    if (
      isGoogleMapsLibLoaded &&
      getDropOffLocationsWorkflowId &&
      contactAddressLocation
    ) {
      loadDropOffLocations(contactAddressLocation);
    }
  }, [isGoogleMapsLibLoaded, contactAddressLocation, runWorkflow]);

  useEffect(() => {
    if (isGoogleMapsLibLoaded && map && contactAddressLocation) {
      map?.panTo(new google.maps.LatLng(contactAddressLocation));
      setZoom(10);
    }
  }, [map, contactAddressLocation, isGoogleMapsLibLoaded]);

  const mapElement = useCallback(
    (formik: FormikProps<DropOffLocationFormValues>) => {
      return (
        <Map
          mapId={"dropOffLocationsMap"}
          defaultZoom={10}
          style={{
            width: "100%",
            height: isDesktop ? "500px" : "30vh",
          }}
          zoomControlOptions={{
            position: window.google?.maps?.ControlPosition.LEFT_BOTTOM,
          }}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          defaultCenter={{lat: 0, lng: 0}}
        >
          {dropOffLocations?.map((dropOffLocation) => {
            return (
              <AdvancedMarker
                key={`${dropOffLocation?.contactAddressId}-${dropOffLocation?.contactId}`}
                onClick={() => handleLocationClick(formik, dropOffLocation)}
                position={
                  new google.maps.LatLng({
                    lat: dropOffLocation?.latitude,
                    lng: dropOffLocation?.longitude,
                  })
                }
              />
            );
          })}
        </Map>
      );
    },
    [zoom, contactAddressLocation, dropOffLocations, isDesktop],
  );

  const handleGooglePlacesAutocompleteChange = useCallback(
    (
      _: FormikProps<DropOffLocationFormValues>,
      value: google.maps.places.PlaceResult | null,
    ) => {
      if (value && value.geometry?.location) {
        loadDropOffLocations(new google.maps.LatLng(value.geometry.location));
        map?.panTo(value.geometry.location);
      }
    },
    [map, loadDropOffLocations],
  );

  const googlePlacesAutocomplete = useMemo(() => {
    return (
      <GooglePlacesAutocomplete
        fieldName={"placeSearch"}
        startAdornment={<GpsFixedIcon />}
        onChange={handleGooglePlacesAutocompleteChange}
      />
    );
  }, [handleGooglePlacesAutocompleteChange]);

  const handleLocationClick = useCallback(
    (
      formik: FormikProps<DropOffLocationFormValues>,
      location: ContactAddress,
    ) => {
      if (map && location?.latitude && location?.longitude) {
        formik.setFieldValue("selected_agent_contact_id", location?.contactId);
        formik.setFieldValue(
          "selected_agent_address_id",
          location?.contactAddressId,
        );
        map.panTo(
          new google.maps.LatLng({
            lat: location.latitude,
            lng: location.longitude,
          }),
        );
      }
    },
    [map],
  );

  const handleSubmit = useCallback(
    (values: DropOffLocationFormValues) => {
      dispatch(
        setOrderCustomValues({
          values: {
            selected_agent_contact_id: values?.selected_agent_contact_id,
            selected_agent_address_id: values?.selected_agent_address_id,
          },
        }),
      );
      if (handleNext) handleNext();
    },
    [dispatch, handleNext],
  );

  return (
    <Grid pt={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          selected_agent_contact_id: yup.number().required(),
          selected_agent_address_id: yup.number().required(),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            {isGoogleMapsLibLoaded && initialValuesLoaded ? (
              <>
                {!formikProps?.values?.placeSearchValue ? (
                  <Grid>{googlePlacesAutocomplete}</Grid>
                ) : null}
                <Grid
                  display={"flex"}
                  flexDirection={{xs: "column-reverse", md: "row"}}
                  mt={2}
                  visibility={
                    formikProps?.values?.placeSearchValue ? "visible" : "hidden"
                  }
                >
                  <>
                    <Grid minWidth={{md: "350px"}}>
                      <Grid pr={{md: 2, xs: 0}} py={{md: 0, xs: 2}}>
                        {googlePlacesAutocomplete}
                      </Grid>
                      {!isDropOffLoading ? (
                        <Paper
                          sx={{
                            maxHeight: {md: "47vh", xs: "40vh"},
                            overflowY: "auto",
                            border: "none",
                            boxShadow: "none",
                          }}
                        >
                          <List>
                            {dropOffLocations.length &&
                            dropOffLocations.length !== 0 ? (
                              <ListSubheader>
                                {dropOffLocations.length +
                                  t(i18nextKeyPrefix + "locationsNearFound")}
                              </ListSubheader>
                            ) : null}
                            {dropOffLocations.length === 0 &&
                            noLocationsFoundMessage ? (
                              <ListItemButton
                                onClick={() => {
                                  if (noLocationsFoundLink)
                                    navigate(noLocationsFoundLink);
                                }}
                              >
                                <Grid
                                  container
                                  width={"100%"}
                                  sx={{
                                    "& a": {
                                      color: theme?.palette?.primary?.main,
                                    },
                                  }}
                                >
                                  {noLocationsFoundMessage}
                                </Grid>
                              </ListItemButton>
                            ) : null}
                            {dropOffLocations.map((location, index) => (
                              <ListItemButton
                                key={`${location.poi?.key}-${index}`}
                                selected={
                                  formikProps?.values
                                    ?.selected_agent_contact_id ===
                                    location?.contactId &&
                                  formikProps?.values
                                    ?.selected_agent_address_id ===
                                    location?.contactAddressId
                                }
                              >
                                <Grid container width={"100%"}>
                                  <Grid xs={9}>
                                    <Typography>
                                      {location.addressLine}
                                    </Typography>
                                    <Typography>
                                      {location.addressLine2}
                                    </Typography>
                                    <Typography>
                                      {[
                                        [
                                          location.cityName,
                                          location.stateCode,
                                        ].join(", "),
                                        location.postalCode,
                                      ].join(" ")}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    xs={3}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"flex-end"}
                                    textAlign={"end"}
                                  >
                                    <Grid>
                                      {(location.distance ?? 0).toFixed(2)} mi
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    gap={2}
                                    paddingTop={2}
                                  >
                                    <Button
                                      href={`https://www.google.com/maps/search/?api=1&query=${location.latitude}%2C${location.longitude}`}
                                      target={"_blank"}
                                    >
                                      <DirectionsIcon sx={{mr: 1}} />
                                      Get directions
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() =>
                                        handleLocationClick(
                                          formikProps,
                                          location,
                                        )
                                      }
                                    >
                                      <Trans
                                        i18nKey={i18nextKeyPrefix + "select"}
                                      >
                                        Select
                                      </Trans>
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ListItemButton>
                            ))}
                          </List>
                        </Paper>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mt={4}
                        >
                          <CircularProgress
                            style={{height: "50px", width: "50px"}}
                          />
                        </Box>
                      )}
                    </Grid>
                    {mapElement(formikProps)}
                  </>
                </Grid>

                <LoadingButton
                  data-testid="btn-submit-locations"
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    p: 1,
                    my: 5,
                    maxWidth: "300px",
                  }}
                  loading={!isGoogleMapsLibLoaded}
                  loadingPosition="center"
                  endIcon={<></>}
                  disabled={
                    !formikProps?.values?.selected_agent_contact_id ||
                    !formikProps?.values?.selected_agent_address_id
                  }
                >
                  <Typography variant="body3" textTransform={"none"}>
                    <Trans i18nKey="btnContinue">Continue</Trans>
                  </Typography>
                </LoadingButton>
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress style={{height: "50px", width: "50px"}} />
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
