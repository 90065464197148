import React from "react";
import {Trans} from "react-i18next";
import i18next from "i18next";
import {Box, Typography} from "@mui/material";
import {DeliveryMethodCardProps} from "./interfaces";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import useDeliveryTransitDaysAsterisk from "../hooks/useDeliveryTransitDaysAsterisk";

export const deliveryMethodBoxSX = {
  border: 1,
  borderColor: {xs: "#0F4A841A", md: "#0F4A841A"},
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 8px 0px rgba(15, 74, 132, 0.1)",

  borderRadius: {md: "8px", xs: "16px"},
  "& .deliveryDaysText": {
    fontWeight: 600,
  },
  "&:hover": {
    md: {
      backgroundColor: "#D8E6FB",
      borderColor: "transparent",
      "& .deliveryDays, .deliveryPrice": {
        color: "#205A9C",
      },
      "& .deliveryDaysText": {
        fontWeight: 600,
      },
      "& .deliveryType": {
        color: "#252631",
      },
    },
  },
};

export const deliveryMethodBoxSXisActive = {
  border: 1,
  backgroundColor: "#2F81EF",
  borderColor: "transparent",
  borderRadius: {md: "8px", xs: "16px"},
  boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
  "& .deliveryDaysText, .deliveryType, .deliveryPrice": {
    color: "#FFFFFF",
  },
  "& .deliveryDaysText": {
    fontWeight: 600,
  },
};

export const deliveryMethodBoxSXisDisabled = {
  border: 1,
  backgroundColor: "#DADADA",
  borderColor: "transparent",
  borderRadius: {md: "8px", xs: "16px"},
  boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
  "& .deliveryDaysText, .deliveryType, .deliveryPrice": {
    color: "#6C757D",
  },
  "& .deliveryDaysText": {
    fontWeight: 600,
  },
};

export default function DeliveryMethodCard({
  deliveryMethod,
  index,
  showTotalCost = false,
}: DeliveryMethodCardProps) {
  const lang = i18next.language;

  const {asterisk} = useDeliveryTransitDaysAsterisk();

  return (
    <>
      <Box
        data-testid={`card-delivery-method-${index}`}
        style={{
          cursor:
            deliveryMethod.isActive || !deliveryMethod.isEnabled
              ? "default"
              : "pointer",
          padding: 16,
        }}
        sx={
          !deliveryMethod.isEnabled
            ? deliveryMethodBoxSXisDisabled
            : deliveryMethod.isActive
            ? deliveryMethodBoxSXisActive
            : deliveryMethodBoxSX
        }
      >
        <Box
          sx={{display: "flex", justifyContent: "space-between"}}
          className="deliveryDays"
        >
          <Box>
            <Typography className="deliveryDaysText" variant="body1">
              <Trans i18nKey="deliveryMethodFrom">from</Trans>&nbsp;
              {deliveryMethod.daysFrom}&nbsp;
              <Trans i18nKey="deliveryMethodTo">to</Trans>&nbsp;
              {deliveryMethod.daysTo}&nbsp;
              <Trans i18nKey="deliveryMethodDays">Days</Trans>
              {asterisk}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            className="deliveryType"
            sx={{color: "#6C757D"}}
            variant="caption3"
          >
            {(deliveryMethod.deliveryType &&
              `service_description_${lang}` in deliveryMethod.deliveryType &&
              deliveryMethod.deliveryType[`service_description_${lang}`]) ??
              ""}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography
            className="deliveryPrice"
            align="right"
            color="#2F81EF"
            variant="body1"
            sx={{fontWeight: 600}}
          >
            {showTotalCost
              ? getFormattedPrice(deliveryMethod.totalAmount)
              : deliveryMethod.estimatedCost}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
