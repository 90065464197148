import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Button} from "@mui/material";
import {DeliveryMethodFragmentProps} from "./interfaces";
import {Trans} from "react-i18next";
import {Helmet} from "react-helmet";
import DeliveryInUSMethodList from "./deliveryInUSMethodsList";
import {useAppSelector} from "../../../store";
import {OrderSlice} from "../../../features/order/parcelShipment-slice";
import useDeliveryTransitDaysAsterisk from "../hooks/useDeliveryTransitDaysAsterisk";

export default function ParcelDeliveryMethodFragment({
  handleNext,
  pageTitle,
  config,
}: DeliveryMethodFragmentProps) {
  const currentOrder = useAppSelector(
    (state: any) => state.parcelState?.order,
  ) as OrderSlice;
  const selectedLastMileMethod = currentOrder?.deliveryMethod;
  const methodIsSelected = selectedLastMileMethod?.rateId != null;

  const {asteriskDescription} = useDeliveryTransitDaysAsterisk();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid sx={{py: 2}} container columns={{xs: 6, md: 12}}>
        <Grid xs={6} md={12} lg={8}>
          <DeliveryInUSMethodList />
          <Grid mt={3} md={3} xs={6}>
            <Button
              data-testid="btn-continue-delivery-method"
              disabled={!methodIsSelected}
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleNext}
              sx={{
                p: 1,
                mb: 3,
              }}
            >
              <Trans i18nKey="btnContinue">Continue</Trans>
            </Button>
          </Grid>
          {asteriskDescription}
        </Grid>
      </Grid>
    </>
  );
}
