import {createTheme} from "@mui/material";
import InterSemiBold from "../../assets/fonts/Inter-SemiBold.ttf";
import InterRegular from "../../assets/fonts/Inter-Regular.ttf";
import InterMedium from "../../assets/fonts/Inter-Medium.ttf";
import React from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    caption3?: React.CSSProperties;
    buttonLink: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    caption3?: React.CSSProperties;
    buttonLink: React.CSSProperties;
  }

  interface PaletteColor {
    border?: string;
    background?: string;
    text?: string;
    shadow?: string;
    gray?: string;
    white?: string;
    darkBlue?: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    caption1: true;
    caption2: true;
    caption3: true;
    buttonLink: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

export let appTheme = createTheme();

appTheme = createTheme(appTheme, {
  palette: {
    action: {
      disabledBackground: "#DADADA",
      disabled: "#252631",
    },
    primary: {
      main: "#3E80FF",
      darkBlue: "#205A9C",
      light: "#98A2B3",
      dark: "#6C757D",
      white: "#FFFFFF",
      gray: "#393A46",
      shadow: "#0F4A841A",
    },
    secondary: {
      main: "#FFDA14",
      light: "#FFB966",
      dark: "#FF9D29",
      contrastText: "#000",
    },
    info: {
      main: "#9FB3D1",
      border: "#D8E6FB",
      background: "#F5F9FF",
      shadow: "#265ca540",
    },
    success: {
      main: "#00BD75",
    },
    error: {
      main: "#F02B00",
    },
  },
  typography: {
    fontFamily: ["Inter Regular", "Inter Medium", "Inter SemiBold"].join(","),
    h1: {
      fontSize: "2rem",
      fontFamily: "Inter SemiBold",
      color: "#101828",
      [appTheme.breakpoints.down("sm")]: {
        fontFamily: "Inter Medium",
      },
    },
    h2: {
      fontSize: "1.25rem",
      fontFamily: "Inter SemiBold",
      // Without font-weight here semibold becomes too bold in Firefox browser
      fontWeight: "300",
      color: "#101828",
      [appTheme.breakpoints.down("sm")]: {
        fontFamily: "Inter Medium",
        fontSize: "1.5rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: "Inter Medium",
      lineHeight: "140%",
      color: "#101828",
      [appTheme.breakpoints.down("sm")]: {
        fontFamily: "Inter Medium",
        fontSize: "1.25rem",
        lineHeight: "140%",
      },
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: "Inter Regular",
      color: "#101828",
      [appTheme.breakpoints.down("sm")]: {
        fontFamily: "Inter Regular",
        fontSize: "1.25rem",
      },
    },
    body1: {
      fontSize: "1rem",
      fontFamily: "Inter Regular",
      color: "#101828",
    },
    body2: {
      fontSize: "1.25rem",
      fontFamily: "Inter Regular",
      color: "#101828",
    },
    body3: {
      fontSize: "1rem",
      fontFamily: "Inter SemiBold",
      color: "#101828",
    },
    body4: {
      fontSize: "1rem",
      fontFamily: "Inter Medium",
      color: "#101828",
    },
    button: {
      fontFamily: "Inter Medium",
      textTransform: "capitalize",
    },
    buttonLink: {
      fontSize: "1rem",
      fontFamily: "Inter Regular",
      lineHeight: "125%",
    },
    caption1: {
      fontSize: "0.875rem",
      fontFamily: "Inter Regular",
    },
    caption2: {
      fontFamily: "Inter Medium",
      fontSize: "0.875rem",
    },
    caption3: {
      fontSize: "0.75rem",
      fontFamily: "Inter Regular",
      lineHeight: "150%",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `        
        @font-face {
          font-family: 'Inter SemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${InterSemiBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: 'Inter Regular';
          src: url(${InterRegular}) format('truetype');
        }
        
        @font-face {
          font-family: 'Inter Medium';
          src: url(${InterMedium}) format('truetype');
        }

        .zsiq_floatmain {
          display: block;
        }

        @media (max-width: 900px) {
          body {
            background-color: #FFFFFF;
          }
          
          .zsiq_floatmain {
            display: none !important;
          }
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "26px",
          lineHeight: "1.45",
          fontSize: "15px",
        },
      },
      variants: [
        {
          props: {variant: "link"},
          style: {
            color: "#3E80FF",
            fontSize: "1rem",
            fontFamily: "Inter SemiBold",
            textTransform: "none",
            padding: "0px 0px 2px",
            height: "fit-content !important",
            transition: "opacity 0.3s linear",
            disableElevation: true,
            disableRipple: true,
            "&.MuiButtonBase-root.MuiButton-root:hover": {
              backgroundColor: "transparent",
              opacity: 0.8,
            },
            "&.MuiButtonBase-root.MuiButton-root:disabled": {
              color: "#6C757D",
            },
          },
        },
        {
          props: {variant: "contained"},
          style: {
            "&:hover": {
              backgroundColor: "#FFDA14",
              color: "#000",
            },
          },
        },
        {
          props: {size: "xlarge"},
          style: {
            height: "52px",
            width: "539px",
            [appTheme.breakpoints.down("sm")]: {
              height: "40px",
              maxWidth: "80vw",
            },
          },
        },
        {
          props: {size: "large"},
          style: {
            height: "52px",
            width: "384px",
            [appTheme.breakpoints.down("sm")]: {
              height: "40px",
              maxWidth: "80vw",
            },
          },
        },
        {
          props: {size: "medium"},
          style: {
            height: "52px",
            [appTheme.breakpoints.down("sm")]: {
              height: "40px",
              maxWidth: "100vw",
            },
          },
        },
        {
          props: {size: "small"},
          style: {
            height: "52px",
            width: "180px",
            [appTheme.breakpoints.down("sm")]: {
              height: "40px",
              maxWidth: "80vw",
            },
          },
        },
        {
          props: {variant: "outlined"},
          style: {
            backgroundColor: "white",
            "&.MuiButtonBase-root.MuiButton-root:hover": {
              backgroundColor: "white",
              opacity: 0.8,
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({theme}: any) => ({
          "& .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-focused)": {
            "& > fieldset": {
              borderColor: theme.palette.primary.light,
            },
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px white inset",
            WebkitTextFillColor: "black",
          },
          padding: "14px 12px",
          height: "24px",
          "&::placeholder": {
            color: "#b1bcc5",
            opacity: "1",
          },
          [appTheme.breakpoints.down("sm")]: {
            padding: "7.5px 12px",
          },
        },
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ownerState, theme}: any) => ({
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              backgroundColor: theme.palette.primary.white,
            }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "6px",
        },
        noOptions: {
          color: "#5A6772",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#5A6772",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        message: {
          fontSize: "1rem",
          fontFamily: "Inter SemiBold",
        },
      },
    },
  },
});
