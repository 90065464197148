import {Button, Fade, Grid, ImageListItem, Modal} from "@mui/material";
import React, {useState} from "react";
import {Trans} from "react-i18next";

type ImageWithModalProps = {
  refImg?: any | null;
  item: {
    uri: string;
    thumbnailUri?: string | null | undefined;
    previewUri?: string | null | undefined;
    fileUri?: string | null | undefined;
    description?: string | null;
  };
};

export const ImageWithModal = ({item, refImg}: ImageWithModalProps) => {
  const [open, setOpen] = useState(false);

  const thumbnailUri =
    item?.thumbnailUri ?? item?.previewUri ?? item?.fileUri ?? item?.uri;
  const previewUri = item?.previewUri ?? item?.fileUri ?? item?.uri;
  const fileUri = item?.fileUri ?? item?.uri;

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = () => {
    if (previewUri) {
      setOpen(true);
    } else {
      handleOpenInNewTab();
    }
  };

  const handleOpenInNewTab = () => {
    if (fileUri) {
      window.open(fileUri, "_blank");
    }
  };

  return (
    <>
      <ImageListItem key={item.uri} sx={{borderRadius: "8px"}}>
        <img
          ref={refImg}
          style={{objectFit: "fill", cursor: "pointer"}}
          src={thumbnailUri}
          alt={item?.description ?? ""}
          loading="lazy"
          onClick={handleImage}
        />
      </ImageListItem>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Button
            onClick={handleOpenInNewTab}
            variant={"outlined"}
            color={"primary"}
            size={"medium"}
            sx={{alignSelf: "flex-end", height: "40px"}}
          >
            <Trans i18nKey={"imageWithModal.download"}>Download</Trans>
          </Button>
          <Fade in={open} timeout={500}>
            <img
              src={previewUri}
              alt="img"
              style={{maxHeight: "80vh", maxWidth: "80vw"}}
            />
          </Fade>
        </Grid>
      </Modal>
    </>
  );
};
