import {
  CreateOrderEntityCommandValues,
  UpdateCommodityPortalCommand,
} from "../../../features/order/order-api";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useAppSelector} from "../../../store";

export const getOrderPutOrderApiArgs = (
  orderId: number,
  values: any,
  customValues: any,
  orderEntities?: CreateOrderEntityCommandValues[] | null,
) => {
  const commodities = values.commodities ?? values[0].commodities;
  const updatedCommodities: UpdateCommodityPortalCommand[] = commodities
    .map((el: any) => {
      return {
        description: el?.description,
        note: el?.note,
        quantity: Number(el?.quantity),
        unitaryValue: Number(el?.unitaryValue),
        commodityId: isNaN(el?.uuid) ? null : el.uuid,
        customValues: customValues ?? {},
        weight: el?.weight,
        pieces: el?.pieces,
        unit: "1",
        weightUnit: el?.weightUnit,
        dimensionsUnit: el?.dimensionsUnit,
        commodityTypeId: el?.commodityType?.commodityTypeId,
        isRemoved: el?.isRemoved,
      };
    })
    .filter((el: any) => !(el.isRemoved && !el.commodityId));

  const updatedOrderEntities: any = orderEntities?.map((entity: any) => {
    return {
      orderEntityId: entity?.orderEntityId,
      orderEntitySequence: entity?.orderEntitySequence,
      entityType: entity?.entityType,
      nonContactName: entity?.nonContactName,
      customValues: entity?.customValues ?? {},
      contactId: entity?.contactId,
    };
  });

  values.commodities = updatedCommodities;
  values.orderEntities = updatedOrderEntities;

  return values;
};

export const usePurchaseCommodityUpdate = () => {
  const [
    runWorkflow,
    {
      isLoading: isUpdateProcess,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useWorkflowExecutionMutation();
  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createUpdatePurchaseWorkflowId,
  );

  const onUpdate = async (
    orderId: number,
    values: any,
    customValues: any,
    orderEntities?: CreateOrderEntityCommandValues[] | null,
  ) => {
    values.workflowId = workflowId;

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {
        variables: {
          orderId: orderId != 0 ? orderId : null,
          order: getOrderPutOrderApiArgs(
            orderId,
            values,
            customValues,
            orderEntities,
          ),
        },
      },
    };

    const response: any = await runWorkflow(executeWorkflowApiArgs);

    if (response.error) {
      throw new Error(response.error);
    }

    return response;
  };

  return {
    onUpdate,
    isUpdateProcess,
    isUpdateError,
    isUpdateSuccess,
  };
};
