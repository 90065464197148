import React, {useCallback, useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, CircularProgress, Typography} from "@mui/material";
import Card from "../../../common/components/card";
import BoxImage from "../../../../images/selectRequestType/box.png";
import BoxesImage from "../../../../images/selectRequestType/boxes.png";
import {useNavigate, useParams} from "react-router-dom";
import i18next, {t} from "i18next";
import {Trans} from "react-i18next";
import {useContinueVerificationModal} from "../../hooks/useContinueVerificationModal";
import {useDispatch} from "react-redux";
import {
  initOrder,
  loadStateFromDraft,
  OrderStepData,
  setIsNewDraft,
} from "../../../features/order/parcelShipment-slice";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";
import {LoadingButton} from "@mui/lab";

type UrlParams = {
  orderId: string;
};

export default function SelectRequestTypeFragment() {
  const navigate = useNavigate();
  const lang = i18next.language;
  const dispatch = useDispatch();

  const {getDraft, deleteDraft} = useDeliveryHelper();
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [draftResult, setDraftResult] = useState<OrderStepData | null>(null);

  const {render: renderContinueVerificationModal} =
    useContinueVerificationModal();

  const {orderId: orderIdsParam} = useParams<keyof UrlParams>() as UrlParams;
  const orderIds = orderIdsParam
    ?.split("&")
    ?.map((x) => Number(x))
    ?.filter((x) => x > 0);

  const [isLoadingRequest, setIsLoadingRequest] =
    useState<"single" | "consolidated" | "draft" | null>(null);

  const onClickSingle = useCallback(async () => {
    if (orderIds) {
      setIsLoadingRequest("single");
      try {
        if (deleteDraft && draftResult) await deleteDraft(orderIds);
        dispatch(initOrder({orderIds: orderIds}));
        dispatch(setIsNewDraft({orderIds: orderIds, isNewDraft: true}));
        navigate("./..");
      } finally {
        setIsLoadingRequest(null);
      }
    }
  }, [dispatch, orderIds, navigate]);

  const onClickConsolidated = useCallback(async () => {
    if (orderIds) {
      setIsLoadingRequest("consolidated");
      try {
        if (deleteDraft && draftResult) await deleteDraft(orderIds);
        dispatch(initOrder({orderIds: [...orderIds, 0]}));
        navigate(
          `/${lang}/delivery/${[...orderIds, 0]?.join("&")}/selectPurchases`,
        );
      } finally {
        setIsLoadingRequest(null);
      }
    }
  }, [lang, navigate, orderIds]);

  const onClickDraft = useCallback(() => {
    const orderIds = draftResult?.orderIds;
    if (orderIds) {
      setIsLoadingRequest("draft");
      try {
        dispatch(initOrder({orderIds}));
        dispatch(loadStateFromDraft({orderIds, draft: draftResult}));
        dispatch(setIsNewDraft({orderIds, isNewDraft: true}));
        navigate(`/${lang}/delivery/${orderIds?.join("&")}/`);
      } finally {
        setIsLoadingRequest(null);
      }
    }
  }, [draftResult, lang]);

  const i18nextPrefix = "delivery.selectRequestType.";

  type RequestType = {
    key: string;
    title: string;
    description: string;
    buttonLabel: string;
    img: any;
    onClick: any;
  };
  const requestTypes: RequestType[] = [
    {
      key: "single",
      title: t(i18nextPrefix + "singleShipment.title"),
      description: t(i18nextPrefix + "singleShipment.description"),
      buttonLabel: t(i18nextPrefix + "singleShipment.buttonLabel"),
      img: BoxImage,
      onClick: onClickSingle,
    },
    {
      key: "consolidated",
      title: t(i18nextPrefix + "consolidatedShipment.title"),
      description: t(i18nextPrefix + "consolidatedShipment.description"),
      buttonLabel: t(i18nextPrefix + "consolidatedShipment.buttonLabel"),
      img: BoxesImage,
      onClick: onClickConsolidated,
    },
  ];

  useEffect(() => {
    if (getDraft && orderIds?.length > 0) {
      setIsLoadingDraft(true);
      getDraft({orderIds: orderIds})
        .then((draftResult) => {
          if (draftResult) setDraftResult(draftResult);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoadingDraft(false);
        });
    }
  }, [getDraft]);

  const renderRequestTypeCard = useCallback(
    (requestType: RequestType) => {
      return (
        <Card key={requestType.key}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            flexDirection={{md: "row", xs: "column"}}
            gap={4}
          >
            <Box width={{md: "168px", xs: "100px"}}>
              <img src={requestType.img} alt={"box"} width={"100%"} />
            </Box>
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              justifyContent={"flex-start"}
              height={"100%"}
            >
              <Grid>
                <Typography variant="h3">{requestType.title}</Typography>
              </Grid>
              <Grid>
                <Typography variant="caption1">
                  {requestType.description}
                </Typography>
              </Grid>
              <Grid mt={"auto"}>
                <LoadingButton
                  variant={"contained"}
                  color={"secondary"}
                  onClick={requestType.onClick}
                  loading={isLoadingRequest === requestType.key}
                  data-testid={`selectRequestType-${requestType.key}`}
                  disabled={
                    isLoadingRequest
                      ? isLoadingRequest !== requestType.key
                      : false
                  }
                  sx={{textTransform: "capitalize", minWidth: "200px"}}
                >
                  {requestType.buttonLabel ?? "Select"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      );
    },
    [isLoadingRequest],
  );

  const draftCard = useMemo(() => {
    if (isLoadingDraft)
      return (
        <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
          <CircularProgress />
        </Grid>
      );

    if (!draftResult) return null;

    const orderIds = draftResult?.orderIds;
    if (!orderIds || !orderIds?.length) return null;
    const isConsolidated = orderIds?.length > 1;

    if (isConsolidated) {
      const prefix = i18nextPrefix + "draftConsolidated.";
      return renderRequestTypeCard({
        key: "draft",
        title: t(prefix + "title"),
        description: t(prefix + "description"),
        buttonLabel: t(prefix + "buttonLabel"),
        img: BoxesImage,
        onClick: onClickDraft,
      } as RequestType);
    } else {
      const prefix = i18nextPrefix + "draftSingle.";
      return renderRequestTypeCard({
        key: "draft",
        title: t(prefix + "title"),
        description: t(prefix + "description"),
        buttonLabel: t(prefix + "buttonLabel"),
        img: BoxImage,
        onClick: onClickDraft,
      } as RequestType);
    }
  }, [renderRequestTypeCard, isLoadingDraft, draftResult, i18nextPrefix]);

  return (
    <>
      <Grid
        mb={8}
        maxWidth={"1230px"}
        gap={3}
        display={"flex"}
        flexDirection={"column"}
      >
        <Grid mt={{md: 0, xs: 2}}>{draftCard}</Grid>
        <Typography variant="h3">
          <Trans i18nKey={i18nextPrefix + "title"}>
            Select delivery option
          </Trans>
        </Typography>

        <Grid
          display={"flex"}
          flexDirection={{md: "row", xs: "column"}}
          gap={4}
        >
          {requestTypes.map((requestType) => {
            return renderRequestTypeCard(requestType);
          })}
        </Grid>
      </Grid>
      {renderContinueVerificationModal()}
    </>
  );
}
