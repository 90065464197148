import {ImageListItem, Typography} from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";

type ExternalFileProps = {
  item: {
    uri: string;
    description?: string | null;
    fileName?: string | null;
  };
};

export const ExternalFile = ({item}: ExternalFileProps) => {
  const handleClick = () => {
    if (item?.uri) {
      window.open(item.uri, "_blank");
    }
  };

  return (
    <>
      <ImageListItem
        key={item.uri}
        sx={{
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          gap: 1,
        }}
        onClick={handleClick}
      >
        <DescriptionIcon color={"primary"} />

        <Typography
          variant={"caption3"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2, // Limits the text to 2 lines (you can adjust this number)
            WebkitBoxOrient: "vertical",
            maxWidth: "100%",
          }}
        >
          {item.fileName ?? ""}
        </Typography>
      </ImageListItem>
    </>
  );
};
