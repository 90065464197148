import * as yup from "yup";

export const getOneTimeLogInValidationSchema = (t: any) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
    tempEmail: yup
      .string()
      .required(t("validation.common.emailRequired"))
      .email(t("validation.common.emailFormat"))
      .max(256, t("validation.common.emailLengthMax")),
    code: yup
      .string()
      .required(t("validation.code.required"))
      .length(6, t("validation.code.length"))
      .matches(/^\d+$/, t("validation.code.digits")),
  });
};
