import {toast, ToastOptions} from "react-hot-toast";
import {t} from "i18next";
import {tryTranslateError} from "./tryTranslateError";

export const toastError = (
  errorObj: any,
  message: string | null = null,
  options: ToastOptions = {},
) => {
  if (Array.isArray(errorObj?.error)) {
    errorObj?.error.forEach((el: any) =>
      toast.error(tryTranslateError(el.message), options),
    );
  } else if (typeof errorObj === "string") {
    if (!errorObj?.toLowerCase()?.includes("workflow"))
      toast.error(errorObj, options);
  } else if (
    errorObj?.data?.title?.includes(
      "Cannot perform this action as this affects linked entities",
    )
  ) {
    toast.error(tryTranslateError(t("toasts.removeEntityError")), options);
  } else {
    let error = errorObj?.error?.data;
    let errorData: string;
    let parsedError: any;
    try {
      try {
        parsedError = JSON.parse(error);
      } catch {
        parsedError = JSON.parse(JSON.stringify(error));
      }
      if (parsedError) error = parsedError;
      try {
        const innerError = JSON.parse(parsedError?.title);
        if (innerError) error = innerError;
      } catch (ex: any) {
        console.log("parsing error", ex);
      }
    } catch (ex: any) {
      console.log("parsing error", ex);
    }

    switch (error?.status || errorObj?.error?.originalStatus) {
      case 400:
      case 401: {
        errorData =
          (error && (error?.title || error?.detail)) || t("toasts.error");
        if (errorData.includes("\r\n")) {
          error.errors = errorData.split("\r\n");
          if (error?.errors) {
            error?.errors?.map((m: any) => toastError(m));
            return;
          }
        }

        if (error?.errors) {
          if (
            !Array.isArray(error?.errors) &&
            typeof error?.errors === "object"
          ) {
            Object.keys(error?.errors)?.map((k: any) => {
              error?.errors[k].map((m: any) => toastError(m));
            });
          }

          if (Array.isArray(error?.errors)) {
            error?.errors?.map((m: any) => toastError(m));
          }
        }

        break;
      }
      case 404:
        try {
          const parsed = JSON.parse(error?.title);
          errorData = parsed?.title;
        } catch {
          errorData =
            (error && (error?.title || error?.detail)) || t("toasts.error");
        }
        break;
      case 500: {
        errorData = t("toasts.serverError");
        break;
      }
      default:
        errorData = message?.length ? "" : t("toasts.error");
        break;
    }
    if (message?.length) {
      errorData = `${message}${errorData ? "\n" + errorData : ""}`;
    }

    // Ignoring general validation error if there are other validation errors
    if (
      errorData.includes("One or more validation errors occurred.") &&
      error?.errors
    )
      return;

    toast.error(tryTranslateError(errorData), options);
  }
};
