import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useAppSelector} from "../../store";
import {useCallback} from "react";
import {toastError} from "../../common/utils/toastMessages";
import {toast} from "react-hot-toast";
import {t} from "i18next";

export type SendOneTimeLoginCodeRequest = {
  requestedEmail: string;
};

export default function useSendOneTimeLoginCode() {
  const [runWorkflow, {isLoading}] = useWorkflowExecutionMutation();

  const sendOneTimeLoginCodeWorkflowId = useAppSelector(
    (state) =>
      state?.organizationConfigState?.workflows?.sendOneTimeLoginCodeWorkflowId,
  );

  const sendCode = useCallback(
    async (request: SendOneTimeLoginCodeRequest) => {
      const workflowVariables: any = {
        ...request,
      };

      const executeQuoteWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: sendOneTimeLoginCodeWorkflowId,
        values: {variables: {...workflowVariables}},
      };

      const response: any = await runWorkflow(executeQuoteWorkflowApiArgs);

      if (
        response.error &&
        Object.keys(response.error?.data?.errors).length !== 0
      ) {
        toastError(response);
        return {result: false, errors: response.error?.data?.errors};
      }

      toast.success(t("toasts.oneTimeCodeSentSuccess"));

      return {result: true, errors: response.error?.data?.errors};
    },
    [runWorkflow],
  );

  return {
    sendCode,
    isLoading,
  };
}
